<template>

<com-content class="contact" :grow="true">

	<com-content :blue="true" class="intro">

		<com-content :inner="true">

			<h1>{{ $route.meta.title }}</h1>

		</com-content>

	</com-content>

	<com-content :grow="true" :inner="true" class="text" :loading="loading">

		<div v-html="html" />

	</com-content>

</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./common/Content')

	},

	data: function() {

		return {
			loading: true,
			html: ''
		}

	},

	created: function() {

		this.load()

	},

	methods: {

		load: function() {

			this.$api.get(this.$route.meta.api).then(function(json) {

				this.html = json.html
				this.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.intro {
	padding: 120px 0px 60px 0px;
}

.is-mobile .intro {
	padding: 80px 0px 50px 0px;
}

.intro h1 {
	font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: #fff;
}

.is-mobile .intro h1 {
	font-size: 24px;
}

.text {
	padding: 40px 10px;
}

.text >>> p {
	color: #333;
	font-size: 16px;
	
	line-height: 20px;
	margin-bottom: 20px;
}

.text >>> p:last-child {
	margin: 0px;
}

.text >>> a {
	color: #1e3054;
	text-decoration: none;
	font-weight: 400;
}

.text >>> a:hover {
	text-decoration: underline;
}

</style>